import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Btn from "../Button/Button";
import styles from "./FormStyle.module.scss";
import { motion } from "framer-motion";
import StepTools from "../StepTools/StepTools";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { register, errorCode } from "../../store/actions";

import queryString from "query-string";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    }
};

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        }
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    error: {
        "& $notchedOutline": {
            border: "1px solid red"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const FormRegisterStepTwo = ({ setRecoverForm }) => {

    const history = useHistory();
    const [eventCode, setEventCode] = useState("REGISTER WITH CODE");
    const [CallCode, setCallCode] = useState("BOOK A CALL");
    const [blockField, setBlockField] = useState(false);
    const [code, setCode] = useState("");
    const [show1, show1Set ] = useState(false);
    const [show2, show2Set ] = useState(false);
    const classes = useStyles();
    const { handleSubmit, control } = useForm();
    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();

    let formData = new FormData();
    formData.append("mail", state.user.mail);
    formData.append("institution", state.user.institution);
    formData.append("name", state.user.name);
    formData.append("password", state.user.password);
    formData.append("password_confirm", state.user.password_confirm);
    formData.append("terms", state.user.terms);
    formData.append("username", state.user.username);
    formData.append("g-recaptcha-response", state.user.captcha);

    useEffect(() => {
        const parsed = queryString.parse(history.location.search);
        if (parsed.c) {
            setCode(parsed.c);
            setBlockField(true);
        }
    }, []);

    useEffect(() => {
        dispatch(errorCode(false));
    }, []);
    
    useEffect(() => {
        (state.registrationCode.length > 0) && show2Set(true);
    }, [state.registrationCode]);

    const onSubmitCode = () => {
        if(show2) {
            formData.append("code", code);
            setEventCode("LOADING");
            dispatch(register(formData, setEventCode, history, "code", state.errorAttempts));
        }
    };

    const onSubmitCall = () => {
        formData.append("code", "");
        setCallCode("LOADING");
        // for (var key of formData.entries()) {
        //     console.log(key[0] + ", " + key[1]);
        // }
        dispatch(register(formData, setCallCode, history, "bookACall"));
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.container}
        >
            <div className={styles.formContainer}>
                <h1>Create an account </h1>
                <h4>Start your free trial now!</h4>
                <StepTools active='two' />

                {state.registrationCode.length > 0 ? (
                    <div>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmitCode)}>
                            <p>Since you are part of an existing study, you will be registered with the
                                activation code provided to you by the coordinator</p>
                            <FormControl className={classes.formControl} fullWidth variant="outlined">
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    defaultValue=''
                                    render={() => (
                                        <TextField
                                            disabled={(blockField) && true}
                                            id="activation"
                                            type="tel"
                                            variant="outlined"
                                            label="Enter your activation code"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                            InputProps={{
                                                classes: {
                                                    root: (state.errorCode) ? classes.error : classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                }
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Btn
                                type="submit"
                                title={eventCode}
                                event={code ? eventCode : "DISABLED"}
                            />
                        </form>
                    </div>
                ) : (
                    <>
                        <div style={{ display: show1 || show2 ? "none" : "block" }}>
                            <h1>Are you part of an existing study?</h1>
                            <div style={{ display: "flex", gap: "12px" }}>
                                <Btn event={"No"} onClick={() => show1Set(!show1)} />
                                <Btn event={"Yes"} onClick={() => show2Set(!show2)} />
                            </div>
                        </div>

                        <div style={{ display: show1 ? "block" : "none" }} >
                            <p>To complete the registration,
                                please book a 15 minute introduction meeting with our team</p>
                            <Btn
                                stylebutton="bookACall"
                                type="submit"
                                title={CallCode}
                                event={blockField ? "DISABLED" : CallCode}
                                onClick={() => {
                                    onSubmitCall();
                                }}
                            />
                            <button
                                className={styles.recoverLink}
                                onClick={() => show1Set(!show1)}
                            >
                                Go back
                            </button>
                        </div>

                        <div style={{ display: show2 ? "block" : "none" }}>
                            <form className={styles.form} onSubmit={handleSubmit(onSubmitCode)}>
                                <p>If you are part of an existing study, please enter the
                                    activation code provided to you by the coordinator</p>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        defaultValue=''
                                        render={() => (
                                            <TextField
                                                disabled={(blockField) && true}
                                                id="activation"
                                                type="tel"
                                                variant="outlined"
                                                label="Enter your activation code"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                                InputProps={{
                                                    classes: {
                                                        root: (state.errorCode) ? classes.error : classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <Btn
                                    type="submit"
                                    title={eventCode}
                                    event={code ? eventCode : "DISABLED"}
                                />
                                <div>
                                    <button
                                        className={styles.recoverLink}
                                        onClick={() => {
                                            show2Set(!show2); 
                                            setCode("");
                                            dispatch(errorCode(false));
                                        }}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}

                <div className={styles.separator}>
                    {/* <p>or</p> */}
                </div>

                <button
                    className={styles.recoverLink}
                    onClick={() => setRecoverForm(false)}
                >
                    Back to update your details
                </button>
            </div>
        </motion.div>
    );
};

export default FormRegisterStepTwo;
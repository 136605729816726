import React from "react";
import {useSelector} from "react-redux";
import styles from "./LeftSide.module.scss";
import brainLogin from "../../assets/images/Brainart_LoginFrontend_Default.png";

import imgTrials from "../../assets/images/logo-trials@2x.svg";
import imgPlatform from "../../assets/images/qmenta-platform.svg";

const LeftSide = () => {

    const state = useSelector((state) => state.general);

    const renderSwitch = () => {
        switch(state.source) {
        case "trials":
            return (<img src={imgTrials} alt="QMENTA Trials" />);
        case "platform":
            return (<img src={imgPlatform} alt="QMENTA Platform" />);
        case "care":
            return (<img src={imgPlatform} alt="QMENTA Care" />);
        default:
            return "";
        }
    };

    return (
        <>
            <div className={styles.leftSideContainer}>
                <div className={styles.logoContainer}>
                    {renderSwitch()}
                </div>
                <div className={styles.imageContainer}>
                    <div className={styles.active}>
                        <img src={brainLogin} alt="background" />
                    </div> 
                </div>
            </div>
        </>
    );
};

export default LeftSide;








import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

const FormOldPasswordCheck = ({ classes, showExistingPassword, setShowExistingPassword, setExistingPassword, editingOldPassSet, event }) => {
    const { control } = useForm();
    const setEventState = useState("SAVE CHANGES")[1];

    const handleClickShowExistingPassword = () => {
        setShowExistingPassword(!showExistingPassword);
    };

    const restartEvent = () => {
        setEventState("SAVE CHANGES");
    };

    return (
        <Controller
            name="old_password"
            control={control}
            defaultValue={""}
            render={() => (
                <TextField
                    id="old_password"
                    type={showExistingPassword ? "text" : "password"}
                    variant="outlined"
                    label="Password"
                    onChange={(e) => setExistingPassword(e.target.value)}
                    onBlur={() => editingOldPassSet(false)}
                    onFocus={() => editingOldPassSet(true)}
                    onInput={() => event === "ERROR" && restartEvent()}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowExistingPassword}
                                >
                                    {showExistingPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.cssInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                        },
                    }}
                />
            )}
        />
    );
};

FormOldPasswordCheck.propTypes = {
    classes: PropTypes.object.isRequired,
    showExistingPassword: PropTypes.bool.isRequired,
    setShowExistingPassword: PropTypes.bool.isRequired,
    setExistingPassword: PropTypes.func.isRequired,
    editingOldPassSet: PropTypes.func.isRequired,
    event: PropTypes.string.isRequired,
};

export default FormOldPasswordCheck;

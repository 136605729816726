import React, {useState} from "react";
import FormRecoverPassword from "../FormRecoverPassword";
import FormLogin from "../FormLogin";
import HeaderLink from "../HeaderLink/HeaderLink";
import {AnimatePresence} from "framer-motion";

const LoginForm = () => {
    const [recoverForm, setRecoverForm] = useState(false);

    return (
        <>
            <section>
                <HeaderLink 
                    text="Do not have an account yet?"
                    button="Register"
                    buttonLink="register"
                />
                <AnimatePresence exitBeforeEnter initial={true}>
                    {!recoverForm ? (
                        <FormLogin setRecoverForm={setRecoverForm} />
                    ) : <FormRecoverPassword
                        key="recover"
                        setRecoverForm={setRecoverForm}
                    />
                    }
                </AnimatePresence>
            </section>
        </>
    );
};

export default LoginForm;


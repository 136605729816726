import React, {useState, useRef, useEffect} from "react";
import styles from "./ChangePasswordProfile.module.scss";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {useDispatch, useSelector} from "react-redux";
import {setNewPasswordFromProfile, getProfile} from "../../store/actions/GeneralActions";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Btn from "../../components/Button/Button";
import {motion} from "framer-motion";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Icon from "../../components/Icon/icon";
import {saveUrlFrom} from "./../../store/actions";
import FormCurrentPasswordCheck from "../../components/Forms/FormCurrentPasswordCheck";


const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    row: {
        display: "flex",
        justifyContent: "space-between"
    },
    formControl: {
        "& > *": {
            marginBottom: "16px"
        }
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const ChangePasswordProfile = () => {

    const {handleSubmit, control} = useForm();
    const classes = useStyles();
    const [validLength, setValidLength] = useState(false);
    const [validUppercase, setValidUppercase] = useState(false);
    const [validDistinct, setValidDistinct] = useState(false);
    const [validSpecial, setValidSpecial] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [areEquals, setAreEquals] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showExistingPassword, setShowExistingPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [editingOldPass, editingOldPassSet] = useState(false);
    const [event, setEvent] = useState("SAVE CHANGES");
    const [passwordWord, setPasswordWord] = useState("");
    const [existingPassword, setExistingPassword] = useState("");
    const [passwordWord2, passwordWord2Set] = useState("");
    const dispatch = useDispatch();
    const currPass = useRef(null);
    const state = useSelector((state) => state.general);

    useEffect(() => {
        dispatch(getProfile());
    }, []);
    
    useEffect(() => {
        dispatch(saveUrlFrom(sessionStorage.getItem("source")));
    }, [state.redirect]);
        
    useEffect(() => {
        comparisonPassword();
        validationDistinct();
    }, [passwordWord, existingPassword, passwordWord2]);

    const onSubmit = () => {
        let formData = new FormData();
        formData.append("old_password", existingPassword);
        formData.append("new_password", passwordWord);

        setEvent("LOADING");

        dispatch(setNewPasswordFromProfile(formData, setEvent, state.redirect));
    };

    const checkValidation = pass => {
        const REGEX = {
            LENGTH: /^.{8,}$/,
            UPPERCASE: /(?=.*?[A-Z])(?=.*?[a-z])/,
            SPECIAL_CHAR: /(?=.*?[^a-zA-Z\d\s])/,
            NUMBER: /(?=.*?[0-9])/,
        };
        setValidLength(REGEX.LENGTH.test(pass));
        setValidUppercase(REGEX.UPPERCASE.test(pass));
        setValidSpecial(REGEX.SPECIAL_CHAR.test(pass));
        setValidNumber(REGEX.NUMBER.test(pass));
        setPasswordWord(pass);
    };

    const validation = (password) => {
        return checkValidation(password ? password : "");
    };

    const validationDistinct = () => {
        if (existingPassword != "" || passwordWord != "") {
            if(existingPassword === passwordWord) {
                setValidDistinct(false);
            } else {
                setValidDistinct(true);
            }
        }
    };

    const comparisonPassword = () => {
        if (passwordWord != "" || passwordWord2 != "") {
            if(passwordWord === passwordWord2) {
                setAreEquals(true);
            } else {
                setAreEquals(false);
            }
        }
    };

    const restartEvent = () => {
        setEvent("SAVE CHANGES");
    }; 

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const passIcon = <Icon uid="check" className={styles.success} />;
    const failIcon = <Icon uid="clear" className={styles.danger} />;

    return (
        <div className={styles.container}>
            <ProfileSidebar active="Change Password"/>

            {state.loggedUser.email ? 
                (
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        className={styles.sideContainer}
                    >
                        <div className={styles.changePasswordContainer}>
                            <h2>Change password</h2>

                            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="username"
                                        as={
                                            <TextField
                                                id="username"
                                                variant="outlined"
                                                label="Username *"
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                }}
                                            />
                                        }
                                        disabled
                                        control={control}
                                        defaultValue={state.loggedUser.email}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <FormCurrentPasswordCheck
                                        classes={classes}
                                        showExistingPassword={showExistingPassword}
                                        setShowExistingPassword={setShowExistingPassword}
                                        setExistingPassword={setExistingPassword}
                                        editingOldPassSet={editingOldPassSet}
                                        event={event}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="new_password"
                                        control={control}
                                        defaultValue={""}
                                        render={() => (
                                            <TextField
                                                disabled={existingPassword.length <= 0}
                                                inputRef={currPass}
                                                id="new_password"
                                                type={showPassword ? "text" : "password"}
                                                variant="outlined"
                                                label="New Password"
                                                onChange={(e) => validation(e.target.value)}
                                                onInput={() => event === "ERROR" && restartEvent()}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={()=>handleClickShowPassword()}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <ul className={styles.passwordValidationContainer}>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {validLength ? passIcon : failIcon}A length of at least 8 characters
                                    </li>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {validUppercase ? passIcon : failIcon}1 Uppercase character and 1 Lowercase character
                                    </li>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {validDistinct ? passIcon : failIcon}Different from the old password
                                    </li>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {validSpecial ? passIcon : failIcon}1 Special character (e.g # ? ! +)
                                    </li>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {validNumber ? passIcon : failIcon}1 Number
                                    </li>
                                </ul>

                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="new_password"
                                        control={control}
                                        defaultValue={""}
                                        render={() => (
                                            <TextField
                                                disabled={passwordWord <= 0}
                                                id="confirm_password"
                                                type={showPasswordConfirm ? "text" : "password"}
                                                variant="outlined"
                                                label="Confirm New Password"
                                                onChange={(e) => passwordWord2Set(e.target.value)}
                                                onInput={() => event === "ERROR" && restartEvent()}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={()=>handleClickShowPasswordConfirm()}
                                                            >
                                                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <ul className={styles.passwordValidationContainer}>
                                    <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                        {areEquals ? passIcon : failIcon}Password Confirmed
                                    </li>
                                </ul>
                                <Btn 
                                    stylebutton={state.source === "trials" ? "trials" : "platform"}
                                    type="submit"
                                    title={event}
                                    event={
                                        (validLength && 
                                        validUppercase && 
                                        validSpecial && 
                                        validNumber && 
                                        validDistinct &&
                                        areEquals) ? ((event === "ERROR" && editingOldPass) ? setEvent("SAVE CHANGES") : event) : "DISABLED"
                                    }
                                />
                            </form>
                        </div>
                    </motion.div>
                ) : (<div className={styles.container}></div>)}
        </div>
    );
};

export default ChangePasswordProfile;
import * as actionType from "../actions/types";

export const INITIAL_STATE = {
    isAuthenticated: false,
    loading: false,
    showOnScroll: true,
    redirect: "",
    source: "",
    tokenResetPassword:"",
    cookieModal: true,
    notifications: [],
    user: {},
    loggedUser: {},
    step2FAModal: "login",
    step2FAProfile: "2fa_setup",
    errorCode: false,
    registrationCode: "",
    cameFrom: "",
    errorAttempts: 0,
    showTerms: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case actionType.LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case actionType.SET_SOURCE:
        return {
            ...state,
            source: action.source,
            redirect: action.redirect
        };
    case actionType.ENQUEUE_SNACKBAR:
        return {
            ...state,
            notifications: [
                ...state.notifications,
                {
                    key: action.key,
                    ...action.notification,
                },
            ],
        };

    case actionType.CLOSE_SNACKBAR:
        return {
            ...state,
            notifications: state.notifications.map(notification => (
                (action.dismissAll || notification.key === action.key)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            )),
        };

    case actionType.REMOVE_SNACKBAR:
        return {
            ...state,
            notifications: state.notifications.filter(
                notification => notification.key !== action.key,
            ),
        };
    case actionType.SET_TOKEN_RESET_PASSWORD:
        return {
            ...state,
            tokenResetPassword: action.payload,
        };
    case actionType.SET_COOKIE_MODAL:
        return {
            ...state,
            cookieModal: !state.cookieModal,
        };
    case actionType.SET_NEW_USER:
        return {
            ...state,
            user: action.payload,
        };
    case actionType.SET_SHOW_ON_SCROLL:
        return {
            ...state,
            showOnScroll: action.payload
        };
    case actionType.SET_PROFILE:
        return {
            ...state,
            loggedUser: action.payload,
        };
    case actionType.SET_PROFILE_LOGIN:
        return {
            ...state,
            loggedUser: {
                ...state.loggedUser,
                username: action.username,
                password: action.password
            }
        };
    case actionType.SET_REGISTRATION_CODE:
        return {
            ...state,
            registrationCode: action.payload
        };
    case actionType.SET_2FA_STEP:
        return {
            ...state,
            step2FAModal: action.payload,
        };
    case actionType.SET_2FA_STEP_PROFILE:
        return {
            ...state,
            step2FAProfile: action.payload,
        };
    case actionType.SET_2FA_TOKEN:
        return {
            ...state,
            loggedUser: {
                ...state.loggedUser, 
                token_2fa: action.payload
            }
        };
    case actionType.SET_ERROR_ATTEMPTS:
        return {
            ...state,
            errorAttempts: state.errorAttempts + 1 
        };
    case actionType.SET_ERROR_CODE:
        return {
            ...state,
            errorCode: action.payload
        };
    case actionType.TO_2FA_FROM:
        return {
            ...state,
            cameFrom: action.payload,
        };
    case actionType.SET_SESSION:
        return {
            ...state,
            loggedUser: action.payload[0],
            isAuthenticated: action.payload[1],
        };
    case actionType.SET_TERMS:
        return {
            ...state,
            showTerms: action.payload
        };
    default:
        return state;
    }
};

export default reducer;

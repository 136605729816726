import React, {useEffect, useState} from "react";
import queryString from "query-string";
import FormRegister from "../FormRegister";
import HeaderLink from "../HeaderLink/HeaderLink";
import FormRegisterStepTwo from "../FormRegisterStepTwo";
import {AnimatePresence} from "framer-motion";
import {useSelector, useDispatch} from "react-redux";
import history from "../../../utils/history";
import { saveRegistrationCode } from "../../../store/actions";

const RegisterForm = () => {

    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const [recoverForm, setRecoverForm] = useState(false);
    const [regCode, regCodeSet] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        switch(state.source) {
        case "trials":
            setUrl("/?s=trials");
            break;
        case "platform":
            setUrl("/?s=platform");
            break;
        case "care":
            setUrl("/?s=care");
            break;
        default:
            return "";
        }
    }, []);

    useEffect(() => {
        const parsed = queryString.parse(history.location.search);
        if (parsed.c) regCodeSet(parsed.c);
    }, []);

    useEffect(() => {
        if (regCode) dispatch(saveRegistrationCode(regCode));
    }, [regCode]);

    return (
        <>
            <section>
                <HeaderLink 
                    text="Already have an account?"
                    button="Login"
                    buttonLink={url}
                />
                <AnimatePresence exitBeforeEnter initial={true}>
                    {!recoverForm ? (
                        <FormRegister setRecoverForm={setRecoverForm} />
                    ) : <FormRegisterStepTwo
                        key="recover"
                        setRecoverForm={setRecoverForm}
                    />
                    }
                </AnimatePresence>
            </section>
        </>
    );
};

export default RegisterForm;
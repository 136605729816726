import React from "react";
import styles from "./ProfileSidebar.module.scss";

let menuOptions = [
    {
        name: "My Data",
        href: "profile/data"
    },
    {
        name: "Change Password",
        href: "profile/change-password"
    },
    {
        name: "2-factor authentication",
        href: "profile/factor-authenticator"
    },
    {
        name: "Credits",
        href: "profile/credits"
    },
    {
        name: "Logout",
        href: "profile/logout"
    }
];

const ProfileSidebar = (props) => {

    return (
        <div className={styles.container}>
            <div className={styles.itemContainer}>
                {
                    menuOptions.map((item, index) => {
                        return(
                            <div className={styles.item} key={index}>
                                <a href={item.href}>
                                    <p className={(item.name === props.active) ? styles.active : ""}>
                                        {item.name}
                                    </p>
                                </a>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ProfileSidebar;

import React from "react";
import styles from "./Register.module.scss";
import LeftSide from "../../components/LeftSide/LeftSide";
import RegisterForm from "../../components/Forms/RegisterForm/RegisterForm";

const Register = () => {

    return (
        <div className={styles.container}>
            <LeftSide/>
            <RegisterForm/>
        </div>
    );
};

export default Register;
import React, {useState} from "react";
import styles from "./CookieConsent.module.scss";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {setCookieModal} from "../../store/actions";
import Btn from "../Button/Button";

const CookieConsent = () => {

    const [showModal, setShowModal] = useState(true);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.general);

    const handleSubmit = () => {
        setShowModal(false);
        setTimeout(() => {
            dispatch(setCookieModal());
        }, 900);
        Cookies.set("CookieConsent", false, {expires: 1});
    };

    return (
        <>
            <div className={(showModal) ? `${styles.cookieContainer} ${styles.show}` : `${styles.cookieContainer} ${styles.hide}`}>
                <div className={styles.modalContainer}>
                    <div className={styles.textContainer}>
                        <p>This web application uses cookies to ensure you get the best experience. </p>
                        <a href="https://www.qmenta.com/cookie-policy/" target="_blank" rel="noreferrer">Learn More</a>
                    </div>
                    <Btn event="Accept & Close" onClick={()=>handleSubmit()} stylebutton={state.source === "trials" ? "trials" : "platform"} />
                </div>
            </div>
        </>
    );
};

export default CookieConsent;

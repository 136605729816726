import * as React from "react";
import styles from "./Warning2FA.module.scss";
import { ReactComponent as AlertTriangle } from "../../assets/icons/alert-triangle.svg";


const textNeed2FA = "Please introduce the code you received on your phone number correctly. The system will block your account after 5 attempts. Please reach out to QMENTA at support@qmenta.com if you need our assistance.";
const textSetup2FA = "Please repeat the process if you did not receive a code and ensure you introduce your correct phone number. If you received the code, you have 5 attempts to introduce it correctly to enable 2FA. Please contact QMENTA at support@qmenta.com if you need our assistance.";

const Warning2FA = ((args) => {
    return (
        <ul className={styles.twoFactorWarningContainer}>
            <li className={"item-li"}>
                <AlertTriangle className={styles.svg} />
                {(args.text === "2fa_need") ? textNeed2FA : textSetup2FA}
            </li>
        </ul>
    );
});

export default Warning2FA;
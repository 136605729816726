import {stack as Menu} from "react-burger-menu";
import React from "react";
import imgPlatform from "../../assets/images/qmenta-platform.svg";
import kebab from "../../assets/images/kebab.svg";
import styles from "./ProfileHeader.module.scss";

let menuOptions = [
    {
        name: "My Data",
        href: "profile/data"
    },
    {
        name: "Change Password",
        href: "profile/change-password"
    },
    {
        name: "2-factor authentication",
        href: "profile/factor-authenticator"
    }
];

const BurgerStyles = {
    bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "30px",
        right: "20px",
        top: "20px"
    },
    bmBurgerBars: {
        background: "#373a47"
    },
    bmBurgerBarsHover: {
        background: "#a90000"
    },
    bmCrossButton: {
        height: "24px",
        width: "24px"
    },
    bmCross: {
        background: "#bdc3c7"
    },

    bmMenuWrap: {
        position: "fixed",
        right: 0,
        top: "73px",
        width:"60%"
    },
    bmMenu: {
        background: "#f2f2f2",
        fontSize: "1.15em"
    },
    bmMorphShape: {
        fill: "#373a47"
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em"
    },
    bmItem: {
        display: "inline-block"
    },
    bmOverlay: {
        position: "fixed",
        right: 0,
        top: "73px",
        width:"100%",
        background: "rgba(0, 0, 0, 0.3)"
    }
};

const ProfileHeader = () => {

    return (
        <div className={styles.container}>            
            <div className={styles.navigationBox}>
                <div className={styles.titleContainer}>
                    <h1>My Profile</h1>
                </div>
                <div className={styles.imageContainer}>
                    <img src={imgPlatform} alt="Qmenta Platform" />
                </div>
                <div className={styles.hamburguerContainer}>
                    <Menu
                        customBurgerIcon={ <img src={kebab} alt="kebab" /> }
                        right
                        styles={BurgerStyles}
                    >
                        <div className={styles.itemContainer}>
                            {
                                menuOptions.map((item, index) => {
                                    return(
                                        <div className={styles.item} key={index}>
                                            <a href={item.href}>
                                                <p>{item.name}</p>
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Menu>
                </div>
                <div className={styles.helpContainer}>
                    {/* <a href="#" target="_blank">
                        <i className="fa fa-life-ring" aria-hidden="true"></i>
                        <p>Help/ Support</p>
                    </a> */}
                </div>
            </div>     
        </div>
    );
};

export default ProfileHeader;

import React, { useState } from "react";
import {useForm, Controller} from "react-hook-form";
import styles from "./FormStyle.module.scss";
// import BackArrow from "../../assets/svg/BackArrow";
import {motion} from "framer-motion";
import Btn from "../Button/Button";
import {startRecoverPassword} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        },
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const FormRecoverPassword = ({ setRecoverForm }) => {
    const classes = useStyles();
    const [event, setEvent] = useState("Reset Password");
    const {handleSubmit, control, errors: fieldsErrors} = useForm();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.general);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("usermail", data.email);
        dispatch(startRecoverPassword(formData, setEvent, setRecoverForm));
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.container}
        >
            <div className={styles.formContainer}>
                {/* <div onClick={() => setRecoverForm(false)} className={styles.back}>
                    <BackArrow />
                </div> */}
                <h1>Reset password</h1>
                <h4>To reset your password please provide your email address and we will send you an activation link to reset it.</h4>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <Controller
                            name="email"
                            as={
                                <TextField
                                    id="email"
                                    helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                                    variant="outlined"
                                    label="Email"
                                    error={fieldsErrors.email}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssInput,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                        }
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        }
                                    }}
                                />
                            }
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                }
                            }}
                        />
                    </FormControl>
                    <Btn type="submit" event={event} stylebutton={state.source === "trials" ? "trials" : "platform"} />
                </form>
                <button
                    className={styles.recoverLink}
                    onClick={() => setRecoverForm(false)}
                >
                    Back to Login
                </button>
            </div>
        </motion.div>
    );
};

export default FormRecoverPassword;

import React, {useEffect} from "react";
import styles from "./ChangePassword.module.scss";
import LeftSide from "../../components/LeftSide/LeftSide";
import ChangePasswordForm from "../../components/Forms/ChangePassword/ChangePasswordForm";
import queryString from "query-string";
import {useDispatch} from "react-redux";
import {saveTokenResetPassword} from "../../store/actions";
import {checkTokenResetPasswordStatus} from "../../store/actions";

const ChangePassword = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        //eslint-disable-next-line
        const parsed = queryString.parse(props.location.search);
        dispatch(saveTokenResetPassword(parsed.c));
    }, []);

    useEffect(() => {
        //eslint-disable-next-line
        const parsed = queryString.parse(props.location.search);
        dispatch(checkTokenResetPasswordStatus(parsed.c));
    }, []);
    

    return (
        <div className={styles.container}>
            <LeftSide/>
            <ChangePasswordForm/>
        </div>
    );
};

export default ChangePassword;
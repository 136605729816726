import React, {useState} from "react";
import styles from "./Data.module.scss";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {saveUrlFrom} from "./../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../store/actions/GeneralActions";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Btn from "../../components/Button/Button";
import {motion} from "framer-motion";
import {makeStyles} from "@material-ui/core/styles";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    row: {
        display: "flex",
        justifyContent: "space-between"
    },
    formControl: {
        "& > *": {
            marginBottom: "16px"
        }
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));


const Data = () => {

    const {handleSubmit, control, errors: fieldsErrors} = useForm();
    const classes = useStyles();
    const [event, setEvent] = useState("SAVE CHANGES");
    const [change, setChange] = useState(false);
    const [fullname, setFullname] = useState(false);
    const [institution, setInstitution] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.general);

    React.useEffect(() => {
        state.isAuthenticated && dispatch(saveUrlFrom(sessionStorage.getItem("source")));
    }, [state.redirect]);

    const onSubmit = () => {
        let formData = new FormData();
        formData.append("name", fullname || state.loggedUser.name);
        formData.append("institution", institution || state.loggedUser.institution);
        formData.append("email", state.loggedUser.email);
        setEvent("LOADING");

        dispatch(updateUser(formData, setEvent));
    };

    const comparison = (value, env) => {
        if(state.loggedUser[env] !== value) {
            setChange(true);
        } else {
            setChange(false);
        }
    };

    return (
        <div className={styles.container}>
            <ProfileSidebar active="My Data"/>
            {state.loggedUser["2fa"] ? 
                (<motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    className={styles.sideContainer}
                >
                    <div className={styles.dataContainer}>
                        <h2>My data</h2>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <FormControl className={classes.formControl} fullWidth variant="outlined">
                                <Controller
                                    name="fullname"
                                    defaultValue={state.loggedUser.name}
                                    render={()=>(
                                        <TextField
                                            id="fullname"
                                            defaultValue={state.loggedUser.name}
                                            helperText={fieldsErrors.fullname ? fieldsErrors.fullname.message : null}
                                            variant="outlined"
                                            label="Fullname *"
                                            error={fieldsErrors.fullname}
                                            onChange={e => {
                                                setFullname(e.target.value);
                                                comparison(e.target.value, "name");
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                        />
                                    )}
                                    control={control}
                                    rules={{
                                        required: "Required"
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth variant="outlined">
                                <Controller
                                    name="institution"
                                    defaultValue={state.loggedUser.institution}
                                    render={()=>(
                                        <TextField
                                            id="institution"
                                            defaultValue={state.loggedUser.institution}
                                            helperText={fieldsErrors.institution ? fieldsErrors.institution.message : null}
                                            variant="outlined"
                                            label="Institution *"
                                            error={fieldsErrors.institution}
                                            onChange={e => {
                                                setInstitution(e.target.value);
                                                comparison(e.target.value, "institution");
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                        />
                                    )}
                                    control={control}
                                    rules={{
                                        required: "Required"
                                    }}
                                />
                            </FormControl>
                            {
                                state.loggedUser["2fa"].to && 
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        defaultValue=""
                                        render={() => (
                                            <TextField
                                                id="phone_number"
                                                disabled
                                                value={state.loggedUser["2fa"].to}
                                                variant="outlined"
                                                label="Phone Number *"
                                                helperText="To update your phone go to the 2 factor authentication section"
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            }
                            <FormControl className={classes.formControl} fullWidth variant="outlined">
                                <Controller
                                    name="email"
                                    as={
                                        <TextField
                                            id="email"
                                            variant="outlined"
                                            label="Email *"
                                        
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                        />
                                    }
                                    defaultValue={state.loggedUser.email}
                                    control={control}
                                    disabled
                                />
                            </FormControl>
                            <Btn 
                                stylebutton={state.source === "trials" ? "trials" : "platform"}
                                type="submit"
                                title={event}
                                event={
                                    (change) ? event : "DISABLED"
                                }
                            />
                        </form>
                    </div>
                </motion.div>) : (<div className={styles.container}></div>)
            }
        </div>
    );
};

export default Data;

import * as React from "react";

const Icon = ((props) => {
    return (
        <span className={`${props.className ? props.className + " " : ""} icon icon-wrapper`}>
            <i {...props} className={(props.type === "outlined") ? "material-icons-outlined" : "material-icons"}>
                {props.uid}
            </i>
        </span>
    );
});

export default Icon;
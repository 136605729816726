import * as React from "react";

function BackArrow(props) {
    return (
        <svg width={31.31} height={28.817} viewBox="0 0 21.31 18.817" {...props}>
            <g data-name="Grupo 26" fill="none" stroke="#000" strokeWidth={2}>
                <path data-name="Trazado 90" d="M10.115 18.109l-8.7-8.7 8.7-8.7" />
                <path data-name="Trazado 91" d="M1.414 9.407h19.9" />
            </g>
        </svg>
    );
}

export default BackArrow;

import React, {useState, useEffect} from "react";
import styles from "./LoadingCredentials.module.scss";
import LeftSide from "../../components/LeftSide/LeftSide";
import lottie from "lottie-web";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import successData from "../../assets/loading-credentials.json";

const LoadingCredentials = () => {

    const [showLoading, setShowLoading] = useState(true);
    const state = useSelector((state) => state.general);
    const history = useHistory();

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#successLottie"),
            animationData: successData,
            loop: false,
            autoplay: true
        });
        setTimeout(() => {
            setShowLoading(false);
        }, 3000);
    }, []);

    useEffect(() => {
        if(!showLoading) {
            if(state.redirect === "") {
                history.push("/profile");
            } else {
                window.location.replace(`${state.redirect}`);
            }
        }        
    }, [showLoading]);

    return (
        <div className={styles.container}>
            <LeftSide/>
            {
                showLoading &&
                <div className={styles.loadingSection}>
                    <div className={styles.loadingBox}>
                        <h2>Checking your credentials</h2>
                        <div className={styles.lottie}>
                            <div className={styles.lottieContainer} id="successLottie"/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default LoadingCredentials;

import React from "react";
import {Link} from "react-router-dom";
import styles from "./HeaderLink.module.scss";

const HeaderLink = (props) => {
    return (
        <>
            <div className={styles.registerForm}>
                <div className={styles.registerText}>
                    <p>{props.text}</p>
                </div>
                <button className={styles.buttonContainer}>
                    <Link className={styles.registerButton} to={props.buttonLink}>
                        {props.button}
                    </Link>
                </button>
            </div>
        </>
    );    
};

export default HeaderLink;
import React, {useState} from "react";
import styles from "./Setup2FA.module.scss";
import {useForm, Controller} from "react-hook-form";
import {FormControl} from "@material-ui/core";
import BackArrow from "../../assets/svg/BackArrow";
import MuiPhoneNumber from "material-ui-phone-number";
import Btn from "../../components/Button/Button";
import {useSelector, useDispatch} from "react-redux";
import {set2FAStep} from "../../store/actions";
import {motion} from "framer-motion";
import {makeStyles} from "@material-ui/core/styles";
import {setup2FA} from "../../store/actions";
import {useHistory} from "react-router-dom";

const pageVariants = {
    initial: {
        opacity: 0,
        y: -500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        },
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const Setup2FA = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {handleSubmit, control} = useForm();
    const [event, setEvent] = useState("SEND PHONE NUMBER");
    const [phoneNumber, setPhoneNumber] = useState(0);
    const state = useSelector((state) => state.general);

    const onSubmit = () => {
        let formData = new FormData();
        formData.append("phone_number", phoneNumber);
        formData.append("token", state.loggedUser.token_2fa);
        dispatch(setup2FA(formData, setEvent, "login", history, state));
        setEvent("LOADING");
    };

    const back = () => {
        props.restartEvent();
        dispatch(set2FAStep("login"));
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.container}
        >
            <div className={styles.formContainer}>
                <div onClick={() => back()} className={styles.back}>
                    <BackArrow />
                </div>
                <h1>Two Factor Authentication</h1>
                <h3>Enter your phone number</h3>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>          
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <Controller
                            name="phone_number"
                            control={control}
                            defaultValue=""
                            render={() => (
                                <MuiPhoneNumber
                                    id="phone_number"
                                    value={(state.loggedUser["2fa"] && state.loggedUser["2fa"].to) ? state.loggedUser["2fa"].to : ""}
                                    variant="outlined"
                                    label="Phone Number"
                                    onChange={phone => setPhoneNumber(phone)}
                                    defaultCountry={"us"}
                                    inputProps={{
                                        required: true,
                                        autoFocus: true
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <Btn 
                        stylebutton={state.source != "trials" && "bookACall"}
                        type="submit"
                        title="Send phone number" 
                        event={
                            (phoneNumber) ? event : "DISABLED"
                        } />
                </form>
            </div>
        </motion.div>
    );
};

export default Setup2FA;
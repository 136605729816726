import axios from "axios";

const environment = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: environment,
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json, text/plain, */*",
    },
});

axiosInstance.defaults.headers.common["x-session-verify"] = localStorage.getItem("session_active");

export {axiosInstance, environment};

import React, {useState, useEffect} from "react";
import styles from "./Credits.module.scss";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {motion} from "framer-motion";
import {useSelector} from "react-redux";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const Credits = () => {

    const [value, setValue] = useState("");
    const state = useSelector((state) => state.general);

    const getUserData = () => {
        let value = state.loggedUser.av_data;
        let gbValue = (value / (1024 * 1024 * 1024)).toFixed(2);
        setValue(gbValue);
    };
    
    useEffect(() => {
        state.loggedUser && getUserData();
    }, [state.loggedUser]);

    return (
        <div className={styles.container}>
            <ProfileSidebar active="Credits"/>
            {(state.loggedUser) && (
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    className={styles.sideContainer}
                >
                    <div className={styles.creditsContainer}>
                        <h1>Credits</h1>
                        <div className={styles.creditsBox}>
                            <h1>Available Credits</h1>
                            <div className={styles.creditsItems}>
                                <p>Upload Quota: <b>{`${value && !isNaN(value) ? value + " GB" : "0 GB"}`}</b></p>
                            </div>
                            <div className={styles.creditsItems}>
                                <p>Analysis Credits: <b>{state.loggedUser.av_projects || "0"}</b></p>
                            </div>
                            <div className={styles.creditsItems}>
                                <p>Analysis Run in Parallel: <b>{state.loggedUser.run_parallel || "0"}</b></p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Credits;

import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import styles from "./FormStyle.module.scss";
import {motion} from "framer-motion";
import Btn from "../Button/Button";
import {useSelector, useDispatch} from "react-redux";
import {closeSnackbar, login} from "../../store/actions";
import {TextField, FormControl} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Setup2FA from "../Setup2FA/Setup2FA";
import Need2FA from "../Need2FA/Need2FA";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        },
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const GreenCheckbox = withStyles({
    root: {
        color: "#00758c",
        "&$checked": {
            color: "#00758c",
        },
    },
    checked: {},
})((props) => <Checkbox {...props} />);

const FormLogin = (props) => {
  
    const classes = useStyles();
    const {handleSubmit, control, errors: fieldsErrors} = useForm();
    const [event, setEvent] = useState("LOGIN");
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(false);

    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append("username", data.email);
        formData.append("password", data.password);
        formData.append("agree_to_terms_and_conditions", checked);
        setEvent("LOADING");
        dispatch(login(formData, setEvent, state.redirect));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCheckbox = () => {
        setChecked(!checked);
    };

    const restartEvent = () => {
        setEvent("LOGIN");
        dispatch(closeSnackbar());
    };

    const renderSwitch = () => {
        switch(state.source) {
        case "trials":
            return (<h1>Log in to QMENTA Trials </h1>);
        case "platform":
            return (<h1>Log in to QMENTA Platform </h1>);
        case "care":
            return (<h1>Log in to QMENTA Care </h1>);
        default:
            return "";
        }
    };

    const renderComponent = () => {
        switch(state.step2FAModal) {
        case "login":
            return (
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    className={styles.container}
                >
                    <div className={styles.formContainer}>
                        {renderSwitch()}
                        <h4>Enter your email and password below</h4>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>          
                            <FormControl className={classes.formControl} fullWidth variant="outlined">
                                <Controller
                                    name="email"
                                    as={
                                        <TextField
                                            id="email"
                                            helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                                            variant="outlined"
                                            label="Email"
                                            error={fieldsErrors.email}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                        />
                                    }
                                    defaultValue=""
                                    control={control}
                                    rules={{
                                        required: "Required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "invalid email address"
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl variant="outlined">
                                <Controller
                                    name="password"
                                    as={
                                        <TextField
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            helperText={fieldsErrors.password ? fieldsErrors.password.message : null}
                                            variant="outlined"
                                            label="Password"
                                            onInput={() => event === "ERROR" && restartEvent()}
                                            error={fieldsErrors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={()=>handleClickShowPassword()}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    root: classes.cssInput,
                                                    focused: classes.focused,
                                                    notchedOutline: classes.notchedOutline,
                                                }
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel
                                                }
                                            }}
                                        />
                                    }
                                    defaultValue=""
                                    control={control}
                                    rules={{
                                        required: "Required"
                                    }}
                                />
                            </FormControl>
                            {state.showTerms && (
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox 
                                            checked={checked}
                                            onChange={()=>handleCheckbox()}
                                            name="terms"
                                        />
                                    }
                                    label={
                                        <div className={styles.disclaimer}>
                                            <span>I accept the </span>
                                            <a className={styles.links} href={"https://www.qmenta.com/terms-of-service/"} target="_blank" rel="noreferrer">Terms and Conditions</a>
                                            <span>, </span>
                                            <a className={styles.links} href={"https://www.qmenta.com/privacy-policy/"} target="_blank" rel="noreferrer">Privacy Policy</a>
                                            <span> and </span>
                                            <a className={styles.links} href={"https://www.qmenta.com/data-policy/"} target="_blank" rel="noreferrer">Data Policy</a>
                                        </div>
                                    }
                                />
                            )}
                            <Btn type="submit" event={event} stylebutton={state.source === "trials" ? "trials" : "platform"} />
                        </form>
                        
                        <button
                            className={styles.recoverLink}
                            onClick={() => props.setRecoverForm(true)}
                        >
                        Reset password
                        </button>
                    </div>
                </motion.div>
            );
        case "2fa_setup":
            return (<Setup2FA restartEvent={()=>restartEvent()} setEvent={setEvent}/>);
        case "2fa_need":
            return (<Need2FA restartEvent={()=>restartEvent()} setEvent={setEvent}/>);
        default:
            return "";
        }
    };

    return (renderComponent());
};

export default FormLogin;

import React, {useState, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import styles from "./FormStyle.module.scss";
import Btn from "../Button/Button";
import {motion} from "framer-motion";
import StepTools from "../StepTools/StepTools";
import {useDispatch, useSelector} from "react-redux";
import {setNewUser, showOnScroll} from "../../store/actions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles, withStyles} from "@material-ui/core/styles";

import Icon from "../Icon/icon";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ReCAPTCHA from "react-google-recaptcha";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        }
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const GreenCheckbox = withStyles({
    root: {
        color: "#00758c",
        "&$checked": {
            color: "#00758c",
        },
    },
    checked: {},
})((props) => <Checkbox {...props} />);

const Register = ({setRecoverForm}) => {

    const recaptchaRef = React.useRef();
    const classes = useStyles();
    const {handleSubmit, control, errors: fieldsErrors} = useForm();
    // eslint-disable-next-line no-unused-vars
    const [event, setEvent] = useState("CONTINUE");

    const [validLength, setValidLength] = useState(false);
    const [validUppercase, setValidUppercase] = useState(false);
    const [validSpecial, setValidSpecial] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [passwordWord, setPasswordWord] = useState("");
    const [passwordWord2, passwordWord2Set] = useState("");
    const [areEquals, setAreEquals] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [checked, setChecked] = useState(false);
    const [prevScrollpos, setPrevScrollpos] = useState(0);

    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        if(currentScrollPos>prevScrollpos) {
            dispatch(showOnScroll(false));
        } else {
            dispatch(showOnScroll(true));
        }
        setPrevScrollpos(currentScrollPos); 
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        comparisonPassword();
    }, [passwordWord, passwordWord2]);

    const comparisonPassword = () => {
        if (passwordWord != "" || passwordWord2 != "") {
            if(passwordWord === passwordWord2) {
                setAreEquals(true);
            } else {
                setAreEquals(false);
            }
        }
    };

    const onSubmit = async (data) => {

        const token = await recaptchaRef.current.executeAsync();

        let user = {};
        user.mail = data.mail;
        user.institution = data.institution;
        user.name = data.name;
        user.password = passwordWord;
        user.password_confirm = passwordWord;
        user.terms = checked;
        user.username = data.mail;
        user.captcha = token;

        dispatch(setNewUser(user));

        setRecoverForm(true);
    };

    const checkValidation = pass => {
        const REGEX = {
            LENGTH: /^.{8,}$/,
            UPPERCASE: /(?=.*?[A-Z])(?=.*?[a-z])/,
            SPECIAL_CHAR: /(?=.*?[^a-zA-Z\d\s])/,
            NUMBER: /(?=.*?[0-9])/,
        };
        setValidLength(REGEX.LENGTH.test(pass));
        setValidUppercase(REGEX.UPPERCASE.test(pass));
        setValidSpecial(REGEX.SPECIAL_CHAR.test(pass));
        setValidNumber(REGEX.NUMBER.test(pass));
        setPasswordWord(pass);
    };

    const validation = (password) => {
        return checkValidation(password ? password : "");
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const handleCheckbox = () => {
        setChecked(!checked);
    };

    const passIcon = <Icon uid="check" className={styles.success} />;
    const failIcon = <Icon uid="clear" className={styles.danger} />;

    const setEnvironment = (value) => {
        // PROCESS.ENV.NODE_ENV RETURN WHAT NPM THE USER IS RUNNING.
        // https://create-react-app.dev/docs/adding-custom-environment-variables/
        // WHEN THE USER RUN LOCALLY (DEVELOPMENT OR TEST), RUN THE GOOGLE TEST KEY.
        // WHEN THE JENKINS RUNS NPM RUN BUILD THE APP RUNS THE VARIABLE SETUP ON THE JENKINSFILE.
        // console.log(value);
        switch(value) {
        case "development" || "test":
            return "6Le4dLkcAAAAAB4ANkC-uotWbe9pfyAyMCJM8aFm";
        case "production" || "staging":
            return process.env.REACT_APP_SITE_KEY;
        default:
            return "6Le4dLkcAAAAAB4ANkC-uotWbe9pfyAyMCJM8aFm";
        }
    };

    // console.log(process.env.REACT_APP_SITE_KEY);

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.container}
        >
            <div onScroll={handleScroll}>
                <div className={styles.formContainer}>
                    <h1>Create an account </h1>
                    <h4>Start your free trial now!</h4>
                    <StepTools active="one"/>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                            <Controller
                                name="name"
                                as={
                                    <TextField
                                        id="name"
                                        helperText={fieldsErrors.fullName ? fieldsErrors.fullName.message : null}
                                        variant="outlined"
                                        label="Full Name *"
                                        error={fieldsErrors.fullName}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.cssInput,
                                                focused: classes.focused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                }
                                control={control}
                                defaultValue={state.user.name ? state.user.name : ""}
                                rules={{
                                    required: "Required"
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                            <Controller
                                name="mail"
                                as={
                                    <TextField
                                        id="mail"
                                        helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                                        variant="outlined"
                                        label="Email *"
                                        error={fieldsErrors.email}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.cssInput,
                                                focused: classes.focused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                }
                                control={control}
                                defaultValue={state.user.mail ? state.user.mail : ""}
                                rules={{
                                    required: "Required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address"
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                            <Controller
                                name="institution"
                                as={
                                    <TextField
                                        id="institution"
                                        helperText={fieldsErrors.institution ? fieldsErrors.institution.message : null}
                                        variant="outlined"
                                        label="Institution *"
                                        error={fieldsErrors.institution}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.cssInput,
                                                focused: classes.focused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                }
                                control={control}
                                defaultValue={state.user.institution ? state.user.institution : ""}
                                rules={{
                                    required: "Required"
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                            <TextField
                                id="password"
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                label="Password *"
                                onChange={(e)=>validation(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>handleClickShowPassword()}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        root: classes.cssInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel
                                    }
                                }}
                            />
                        </FormControl>
                        <ul className={styles.passwordValidationContainer}>
                            <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                {validLength ? passIcon : failIcon}A length of at least 8 characters
                            </li>
                            <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                {validUppercase ? passIcon : failIcon}1 Uppercase character and 1 Lowercase character
                            </li>
                            <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                {validSpecial ? passIcon : failIcon}1 Special character (e.g # ? ! +)
                            </li>
                            <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                {validNumber ? passIcon : failIcon}1 Number
                            </li>
                        </ul>
                        <FormControl className={classes.formControl} fullWidth variant="outlined">
                            <TextField
                                id="password_confirm"
                                type={showPasswordConfirm ? "text" : "password"}
                                variant="outlined"
                                label="Confirm Password *"
                                onChange={(e)=>passwordWord2Set(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>handleClickShowPasswordConfirm()}
                                            >
                                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        root: classes.cssInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel
                                    }
                                }}
                            />
                        </FormControl>
                        <ul className={styles.passwordValidationContainer}>
                            <li className={`item-li ${validation.length ? "valid" : ""}`}>
                                {areEquals ? passIcon : failIcon}Password Confirmed
                            </li>
                        </ul>
                        <FormControlLabel
                            control={
                                <GreenCheckbox 
                                    checked={checked}
                                    onChange={()=>handleCheckbox()}
                                    name="terms"
                                />
                            }
                            label={
                                <div className={styles.disclaimer}>
                                    <span>I accept the </span>
                                    <a className={styles.links} href={"https://www.qmenta.com/terms-of-service/"} target="_blank" rel="noreferrer">Terms and Conditions</a>
                                    <span>, </span>
                                    <a className={styles.links} href={"https://www.qmenta.com/privacy-policy/"} target="_blank" rel="noreferrer">Privacy Policy</a>
                                    <span> and </span>
                                    <a className={styles.links} href={"https://www.qmenta.com/data-policy/"} target="_blank" rel="noreferrer">Data Policy</a>
                                </div>
                            }
                        />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={setEnvironment(process.env.NODE_ENV)}
                        />
                        <Btn 
                            type="submit"
                            stylebutton={state.source === "trials" ? "trials" : "platform"}
                            title={event}
                            event={(
                                validLength && 
                                validUppercase && 
                                validSpecial && 
                                validNumber && 
                                areEquals && 
                                checked) ? event : "DISABLED"
                            } 
                        />
                    </form>
                </div>
            </div>
        </motion.div>
    );
};

export default Register;
import * as React from "react";
import styles from "./StepTools.module.scss";

const StepTools = (props => {
    return (
        <div className={styles.container}>
            <div className={`${styles.stepContainer} ${styles.active}`}>
                <span className={styles.number}>1</span>
                <span className={styles.text}>Enter your details</span>
            </div>
            <hr className={(props.active === "two") ? styles.hractive : null }/>
            <div className={(props.active === "one") ? styles.stepContainer : `${styles.stepContainer} ${styles.active}`}>
                <span className={styles.number}>2</span>
                <span className={styles.text}>Confirmation</span>
            </div>
        </div>
    );
});

export default StepTools;
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./containers/Login/Login";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import Register from "./containers/Register/Register";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Notifier from "./containers/Notifier/notifier";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import Profile from "./components/Profile/Profile";
import queryString from "query-string";

import history from "./utils/history";
import { saveUrlFrom, setCookieModal, setAuthentication } from "./store/actions";

import Cookies from "js-cookie";
import useGaTracker from "./useGaTracker";


const App = () => {
    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const [redirect, redirectSet] = useState(null);
    useGaTracker();

    useEffect(() => {
        dispatch(setAuthentication());
        if (Cookies.get("CookieConsent")) {
            dispatch(setCookieModal());
        }
        const parsed = queryString.parse(history.location.search);
        if (parsed.s) {
            redirectSet(parsed.s);
        } else {
            redirectSet(sessionStorage.getItem("source"));
        }
    }, []);

    useEffect(() => {
        if (redirect) {
            dispatch(saveUrlFrom(redirect));
        } else {
            dispatch(saveUrlFrom("platform"));
        }
    }, [state.redirect]);

    // -------------- CODE TO DEPRECATE AFTER CAVSMS PROJECT END -------------------

    // We have provided registration links with activation codes for
    // different roles and sites in the CAVSMS project,
    // of the form https://client.qmenta.com/#/register?c=123456789
    // which now re-directs to https://me.qmenta.com/?s=platform#/register?c=123456789
    // which does not work for registration. The code below will update
    // the URL to be compatible.

    const parsedCavsMsActivationCode = queryString.parse(history.location.hash);

    useEffect(() => {
        if (parsedCavsMsActivationCode["/register?c"]) {
            window.location.replace(`${window.location.origin}${window.location.pathname}register?c=${parsedCavsMsActivationCode["/register?c"]}`);
        }
    });

    // -------------- END OF THE CODE TO DEPRECATE -------------------

    return (
        //eslint-disable-next-line no-undef
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Notifier />
            {state.cookieModal && <CookieConsent />}
            <Switch>
                {Cookies.get("AUTH_COOKIE") ?
                    <Route path="/profile" component={Profile} /> :
                    <Redirect from="/profile" to={"/"} />
                }
                <Route path="/profile" component={Profile} />
                <Route exact path="/change_password" component={ChangePassword} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/" component={Login} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
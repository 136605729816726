import React, {useEffect} from "react";
import lottie from "lottie-web";

import successData from "../../assets/loading-lottie.json";
import styles from "./Button.module.scss";

const Btn = (props) => {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#successLottie"),
            animationData: successData,
            loop: true,
            autoplay: true
        });
    }, [props.event]);

    let state = "";
    let classN = "";

    switch (props.event) {
    case "LOADING":
        classN = "disabled";
        state = (
            <div className={styles.lottieContainer}>
                <div className={styles.lottie} id="successLottie"/>
            </div>
            
        );
        break;
    case "SUCCESS":
        classN = "success";
        state = (
            <>
                <span>SUCCESS </span>
                <i className="fa fa-check"></i>
            </>);
        break;
    case "ERROR":
        classN = "error";
        state = (
            <>
                <span>ERROR </span>
                <i className="fa fa-exclamation-circle"></i>
            </>
        );
        break;
    case "DISABLED":
        classN = "disabled";
        state = props.title;
        break;
    default:
        state = props.event;
        break;
    }

    const handleClassN = () => {
        switch (classN) {
        case "success":
            return { opacity: 1, background: "#00758c" };
        case "error":
            return {
                opacity: 1,
                background: "#9b1a06",
            };
        case "disabled":
            return {
                cursor: "not-allowed",
            };
        default:
            return {};
        }
    };
    const classNConfig = handleClassN();
    return (
        <button
            className={(props.stylebutton === "bookACall") ? `${styles.button} ${styles.blue}` : ((props.stylebutton === "trials") ? `${styles.button} ${styles.trials}` : `${styles.button} ${styles.platform}`)}
            style={classNConfig}
            disabled={classN === "disabled" || props.validation}
            {...props}
            data-testid="button"
        >
            {state}
        </button>
    );
};

export default Btn;

import React from "react";
import styles from "./Login.module.scss";
import LeftSide from "../../components/LeftSide/LeftSide";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";
import {useSelector} from "react-redux";
import LoadingCredentials from "../LoadingCredentials/LoadingCredentials";

const Login = () => {

    const state = useSelector((state) => state.general);

    if(state.isAuthenticated) {
        return (
            <div>
                <LoadingCredentials/>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <LeftSide/>
                <LoginForm/>
            </div>
        );
    }


};

export default Login;

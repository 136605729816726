import React, {useState, useEffect} from "react";
import styles from "./TwoFactor.module.scss";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {useDispatch, useSelector} from "react-redux";
import {setup2FA} from "../../store/actions/GeneralActions";
import MuiPhoneNumber from "material-ui-phone-number";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Btn from "../../components/Button/Button";
import {makeStyles} from "@material-ui/core/styles";
import {motion} from "framer-motion";
import {withStyles} from "@material-ui/core/styles";
import ReactCodeInput from "react-verification-code-input";
import {useHistory} from "react-router-dom";
import {saveUrlFrom} from "./../../store/actions";
import FormCurrentPasswordCheck from "../../components/Forms/FormCurrentPasswordCheck";
import Warning2FA from "../../components/Warning2FA/Warning2FA";


const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const PurpleSwitch = withStyles({
    switchBase: {
        color: "#cccccc",
        "&$checked": {
            color: "#00758c",
        },
        "&$checked + $track": {
            backgroundColor: "#8f9194",
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles(() => ({
    row: {
        display: "flex",
        justifyContent: "space-between"
    },
    formControl: {
        "& > *": {
            marginBottom: "16px"
        }
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const TwoFactor = () => {
    const history = useHistory();
    const recaptchaRef = React.useRef();
    const {handleSubmit, control} = useForm();
    const classes = useStyles();
    const [event, setEvent] = useState("SAVE CHANGES");
    const dispatch = useDispatch();
    const state = useSelector((state) => state.general);

    const [checked, setChecked] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [twoFACode, setTwoFACode] = useState(0);

    const [showExistingPassword, setShowExistingPassword] = useState(false);
    const [existingPassword, setExistingPassword] = useState("");
    const [editingOldPass, editingOldPassSet] = useState(false);

    useEffect(() => {
        dispatch(saveUrlFrom(sessionStorage.getItem("source")));
    }, [state.redirect]);

    useEffect(() => {
        if(state.loggedUser["2fa"] && state.loggedUser["2fa"].to) {
            setChecked(true);
            setPhoneNumber(state.loggedUser["2fa"].to);
        }

    }, [state.loggedUser["2fa"]]);
    
    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    const onSubmitPhone = () => {
        setEvent("LOADING");
        let formData = new FormData();
        formData.append("phone_number", phoneNumber);
        formData.append("checked", checked);
        formData.append("password", existingPassword);
        dispatch(setup2FA(formData, setEvent, "profile", history, state));
    };

    const onSubmitCode = (data) => {
        setEvent("LOADING");
        let formData = new FormData();
        formData.append("code_2fa", data);
        formData.append("token", state.loggedUser.token_2fa);
        recaptchaRef.current.__clearvalues__();
        dispatch(setup2FA(formData, setEvent, "profile", history, state));
    };

    const renderComponent = () => {
        switch(state.step2FAProfile) {
        case "2fa_setup":
            return (
                <>
                    <h2>2-factor authentication</h2>
                    <p>Enable / disable 2-factor authentication. Authentication code will be sent to your email/phone</p>
                    <FormGroup>
                        <FormControl>
                            <FormCurrentPasswordCheck
                                classes={classes}
                                showExistingPassword={showExistingPassword}
                                setShowExistingPassword={setShowExistingPassword}
                                setExistingPassword={setExistingPassword}
                                editingOldPassSet={editingOldPassSet}
                                event={event}
                            />
                        </FormControl>
                        <div className={styles.switchContainer}>
                            <p className={(state.loggedUser["2fa"].enforced)? styles.disabled : styles.enabled}>Disabled</p>
                            <FormControlLabel
                                disabled={state.loggedUser["2fa"].enforced}
                                control={
                                    <PurpleSwitch 
                                        checked={checked}
                                        onChange={toggleChecked} 
                                    />
                                }
                                label={"Enabled"}
                            />
                        </div>
                        {
                            (checked || state.loggedUser["2fa"].enforced) &&
                            <div>
                                <h2>Enter your phone number</h2>
                                
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        defaultValue=""
                                        render={() => (
                                            <MuiPhoneNumber
                                                value={state.loggedUser["2fa"].to}
                                                id="phone_number"
                                                variant="outlined"
                                                label="Phone Number"
                                                onChange={phone => setPhoneNumber(phone)}
                                                defaultCountry={"us"}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: classes.cssLabel
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssInput,
                                                        focused: classes.focused,
                                                        notchedOutline: classes.notchedOutline,
                                                    },
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                        }
                    </FormGroup>
                    <Btn 
                        stylebutton={state.source === "trials" ? "trials" : "platform"}
                        type="submit"
                        title={event}
                        event={(checked ||
                            (state.loggedUser["2fa"] && state.loggedUser["2fa"].to))
                            ? ((event === "ERROR" && editingOldPass) ? setEvent("SAVE CHANGES") : event) : "DISABLED"}
                        onClick={()=>onSubmitPhone()}
                    />
                </>
            );
        case "2fa_need":
            return (
                <div className={styles.formContainer}>
                    <h1>Two Factor Authentication</h1>
                    <h3>Enter the code</h3>
                    <ReactCodeInput
                        ref={recaptchaRef}
                        onChange={value => setTwoFACode(value)}
                        onComplete={(data)=> handleSubmit(onSubmitCode(data))}
                    />
                    <Warning2FA text="2fa_setup"/>
                    <Btn 
                        stylebutton={state.source === "trials" ? "trials" : "platform"}
                        type="submit"
                        title="Send code" 
                        event={
                            (twoFACode.length === 6) ? event : "DISABLED"
                        }
                        onClick={()=>onSubmitCode()}
                    />
                </div>
            );
        default:
            return "";
        }
    };

    return (
        <div className={styles.container}>
            <ProfileSidebar active="2-factor authentication"/>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                className={styles.sideContainer}
            >
                {(state.loggedUser["2fa"]) ? (
                    <div className={styles.twoFactorContainer}>
                        {renderComponent()}
                    </div>
                ) : (<div className={styles.container}></div>)}
                
            </motion.div>
        </div>
    );
};

export default TwoFactor;

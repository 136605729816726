import React from "react";
import FormChangePassword from "../FormChangePassword";
import HeaderLink from "../HeaderLink/HeaderLink";

const ChangePasswordForm = () => {

    return (
        <>
            <section>
                <HeaderLink 
                    text="Already have an account?"
                    button="Login"
                    buttonLink="/"
                />
                <FormChangePassword/>
            </section>
        </>
    );
};

export default ChangePasswordForm;


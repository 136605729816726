//GENERAL

export const LOADING = "LOADING";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const SET_SOURCE = "SET_SOURCE";
export const SET_TOKEN_RESET_PASSWORD = "SET_TOKEN_RESET_PASSWORD";
export const SET_COOKIE_MODAL = "SET_COOKIE_MODAL";
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_SHOW_ON_SCROLL = "SET_SHOW_ON_SCROLL";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_LOGIN = "SET_PROFILE_LOGIN";
export const SET_2FA = "SET_2FA";
export const SET_2FA_STEP = "SET_2FA_STEP";
export const SET_2FA_STEP_PROFILE = "SET_2FA_STEP_PROFILE";
export const SET_2FA_TOKEN = "SET_2FA_TOKEN";
export const SET_ERROR_CODE = "SET_ERROR_CODE";
export const SET_ERROR_ATTEMPTS = "SET_ERROR_ATTEMPTS";
export const TO_2FA_FROM = "TO_2FA_FROM";
export const SET_SESSION = "SET_SESSION";
export const SET_REGISTRATION_CODE = "SET_REGISTRATION_CODE";
export const SET_TERMS = "SET_TERMS";